
import { computed, defineComponent, ref } from 'vue';
import { GuiDatePicker, GuiButton, GuiLoader } from '@library/gigant_ui';
import { SelectItem } from '@/lib/formFactory/select.interface';
import AppFieldset from '@/components/ui/Fieldset/Fieldset.vue';
import FormSelect from '@/components/form/Select/index.vue';
import LaborRelationsModule from '@/store/settings/laborRelations';
import useDateRange from './dateRange';

export default defineComponent({
  components: {
    AppFieldset,
    FormSelect,
    GuiDatePicker,
    GuiButton,
    GuiLoader,
  },
  setup() {
    const employeeSearchString = ref('');
    const employeeSearchNoResultTitle = computed(() =>
      employeeSearchString.value ? undefined : 'Начните вводить ФИО или ИНН'
    );

    const isSaving = computed(() => LaborRelationsModule.isSaving);

    const model = computed(() => LaborRelationsModule.model);
    const startDate = computed(() => model.value.startDate);
    const endDate = computed(() => model.value.endDate);
    const clients = computed(() => LaborRelationsModule.clients);

    const startDateComponent = ref<InstanceType<typeof GuiDatePicker>>();
    const endDateComponent = ref<InstanceType<typeof GuiDatePicker>>();
    const { minStartDate, maxStartDate, minEndDate, maxEndDate } = useDateRange(startDate, endDate, true);

    const employees = ref([] as SelectItem[]);
    let lastEmployeeSearch: Promise<SelectItem[]> | null = null;

    async function searchEmployees(searchString: string): Promise<void> {
      if (searchString.includes('ИНН: ')) {
        searchString = searchString.replace(/ \(ИНН: \d+?\)/, '');
      }

      employeeSearchString.value = searchString;

      if (!searchString) {
        lastEmployeeSearch = null;
        employees.value = [];

        return;
      }

      const preparedSearch = LaborRelationsModule.searchEmployees(searchString);
      lastEmployeeSearch = preparedSearch;

      const employeeItems = await preparedSearch;

      if (lastEmployeeSearch === preparedSearch) {
        employees.value = employeeItems;
      }
    }

    async function save(): Promise<void> {
      const isSavedRelation = await LaborRelationsModule.saveRelation();

      if (isSavedRelation) {
        await clear();
      }
    }

    async function clear(): Promise<void> {
      await LaborRelationsModule.context.commit('CLEAR_MODEL');
      startDateComponent.value?.handler.clearDate();
      endDateComponent.value?.handler.clearDate();
    }

    return {
      isSaving,
      model,
      clients,
      employees,
      minStartDate,
      maxStartDate,
      minEndDate,
      maxEndDate,
      startDateComponent,
      endDateComponent,
      employeeSearchNoResultTitle,
      searchEmployees,
      save,
      clear,
    };
  },
});
