
import { computed, defineComponent, onMounted } from 'vue';
import { GuiLoader } from '@library/gigant_ui';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import AppTable from '@/components/ui/table/Table.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import UserModule from '@/store/user';
import SettingsModule from '@/store/settings';
import LaborRelationsModule from '@/store/settings/laborRelations';
import LaborRelationsFilters from './_filters.vue';
import LaborRelationsForm from './_form.vue';

export default defineComponent({
  components: {
    GuiLoader,
    TabsNav,
    AppTable,
    AppRow,
    AppCol,
    TextDatetime,
    LaborRelationsFilters,
    LaborRelationsForm,
  },
  setup() {
    const tabsNav = computed(() => SettingsModule.tabsNav);
    const currentTab = computed(() => SettingsModule.allTabs.laborRelations.id);
    const isLoadingList = computed(() => LaborRelationsModule.isLoading);
    const isInitiatedTable = computed(() => Object.keys(settings.value.table).length);
    const settings = computed(() => LaborRelationsModule.pageSettings);

    function checkCanEdit() {
      return UserModule.userHasPermission('CAN_EDIT_LABOR_RELATIONS');
    }

    function selectAmount(value: string) {
      LaborRelationsModule.updatePageAmountItems(value);
    }

    async function removeRelation(id: string) {
      await LaborRelationsModule.removeRelation(id);
    }

    onMounted(async () => {
      await SettingsModule.initTabsItems();
      await LaborRelationsModule.init();
    });

    return {
      tabsNav,
      currentTab,
      isLoadingList,
      isInitiatedTable,
      settings,
      checkCanEdit,
      selectAmount,
      removeRelation,
    };
  },
});
