import { Action, Module, Mutation, getModule } from 'vuex-module-decorators';
import moment from 'moment';

import store from '@/store';
import UserModule from '@/store/user';
import PageBaseModule from '@/store/page';
import FilterModule from '@/store/filter';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { strings } from '@/lib/stringConst';
import { UITable } from '@/lib/util/tableUtils';
import { convertObjectToQueryParams } from '@/lib/Utils';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { DatePickerEntityList } from '@/lib/layouts/page/filterDatePicker.interface';
import { SelectItem } from '@/lib/formFactory/select.interface';
import { EmployeeRequestModel } from '@/interfaces/models/employees.interface';
import { getClients } from '@/api/client';
import { getEmployeesList } from '@/api/employees';
import { createLaborRelation, deleteLaborRelation, getLaborRelationsList } from '@/api/laborRelations';

import filterModel from '@/store/settings/laborRelations/filter';
import LaborRelationsFilter from '@/store/settings/laborRelations/filterEntity';

export const MODULE_NAME = 'laborRelations';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class LaborRelationsModule extends PageBaseModule {
  isLoading = true;
  isSaving = false;

  filter: FilterModule;
  clients: SelectItem[] = [];
  model: {
    client: SelectItem;
    employee: SelectItem;
    startDate?: Date;
    endDate?: Date;
  } = {
    client: { id: '', value: '' },
    employee: { id: '', value: '' },
  };

  constructor(module: LaborRelationsModule) {
    super(module);

    const page = new PageEntity();
    page.values.actionPagination = 'laborRelations/updatePage';
    this.pageSettings = page.values;

    const filter = new filterModel();
    this.filter = getModule(LaborRelationsFilter);
    this.filter.setFilterName('laborRelationsFilter');
    this.filter.setFilterModel(filter.filterModel);
  }

  @Mutation
  UPDATE_IS_LOADING(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  UPDATE_CLIENTS(value: SelectItem[]) {
    this.clients = value;
  }

  @Mutation
  CLEAR_MODEL() {
    const createEmptySelectItem = () => ({ id: '', value: '' });

    this.model = {
      client: createEmptySelectItem(),
      employee: createEmptySelectItem(),
      startDate: undefined,
      endDate: undefined,
    };
  }

  @Mutation
  SET_IS_SAVING(value: boolean) {
    this.isSaving = value;
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    const uiTable = new UITable(table);
    const sorts = Object.values(this.pageSettings.sort);
    const canEdit = UserModule.userHasPermission('CAN_EDIT_LABOR_RELATIONS');

    uiTable.changeTitleOrder('client', 0).changeTitleOrder('competence', 1).setSortableValues(sorts);

    if (!canEdit) {
      uiTable.removeColumn('_actions');
    }

    this.context.commit('SET_TABLE', uiTable.getTable());
  }

  @Action({ rawError: true })
  async init() {
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.filter.init();
    await this.initDateFilters();

    await this.getList();
  }

  @Action({ rawError: true })
  async initDateFilters() {
    const storageDateFilterKey = 'laborRelationsFilterDate';
    const startDateKey = `${storageDateFilterKey}StartDate`;
    const endDateKey = `${storageDateFilterKey}EndDate`;

    const savedStartValue = window.localStorage[startDateKey];
    const savedEndValue = window.localStorage[endDateKey];

    const dateFilter = this.filter.filterSettings.filterModel.date?.list as DatePickerEntityList;

    dateFilter.startDate.value = (!!savedStartValue && JSON.parse(savedStartValue)) || '';
    dateFilter.endDate.value = (!!savedEndValue && JSON.parse(savedEndValue)) || '';
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', {
      moduleName: MODULE_NAME,
      amountItems: number,
    });

    await this.getList();
  }

  @Action({ rawError: true })
  async getList() {
    try {
      this.context.commit('UPDATE_IS_LOADING', true);

      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const filter = this.filter.filterSettings.filter;
      const result = await getLaborRelationsList(this.pageSettings.pageCurrent, itemsQuery, filter);

      await this.context.dispatch('setList', result);
    } catch (error) {
      this.context.commit('setGlobalError', false);
    } finally {
      this.context.commit('UPDATE_IS_LOADING', false);
    }
  }

  @Action({ rawError: true })
  initDates() {
    const startDate = moment().add(-29, 'days');
    const today = moment().add(-2, 'days');

    return {
      startDate: { day: startDate.day(), month: startDate.month(), year: startDate.year() },
      endDate: { day: today.day(), month: today.month(), year: today.year() },
    };
  }

  @Action({ rawError: true })
  async initClientsSelect() {
    let clients: SelectItem[] = [];

    try {
      const result = await getClients(false);

      clients = result.clients?.map((client) => ({ id: client.id, value: client.name }));

      this.context.commit('UPDATE_CLIENTS', clients);
    } catch (error) {
      console.error(error);
    }

    return clients;
  }

  @Action({ rawError: true })
  async changeFilterName(searchString: string): Promise<void> {
    this.filter.updateSearch({ key: 'fio', value: searchString });

    await this.updateFilter();
  }

  @Action({ rawError: true })
  async changeFilterSelect<T extends { key: string; value: { id: string; value: string } }>({
    type,
    changeParams,
  }: {
    type: string;
    changeParams: T | string;
  }) {
    if (type === 'update') {
      this.filter.updateSelect(changeParams as T);
    } else if (type === 'clear') {
      this.filter.resetSelect(changeParams as string);
    }

    await this.updateFilter();
  }

  @Action({ rawError: true })
  async changeFilterDate({ value, type }: { value: Date | number; type: 'start' | 'end' }) {
    const date = { year: '', month: '', day: '' };

    if (value instanceof Date) {
      date.year = value.getFullYear().toString().substring(2);
      date.month = (value.getMonth() + 1).toString().padStart(2, '0');
      date.day = value.getDate().toString().padStart(2, '0');
    }

    if (type === 'start') {
      this.filter.updateStartDate({ key: 'date', date });
    } else {
      this.filter.updateEndDate({ key: 'date', date });
    }

    await this.updateFilter();
  }

  @Action({ rawError: true })
  async updateFilter(): Promise<void> {
    await this.SET_PAGE(1);
    await this.filter.updateFilter();
    await this.getList();
  }

  @Action({ rawError: true })
  async searchEmployees(value: string): Promise<SelectItem[]> {
    let filters = '';
    const isLooksLikeInn = /^\s*\d+?\s*$/.test(value);

    if (isLooksLikeInn) {
      const innFilter = { filters: [{ id: 'inn', value }] };
      filters = '&' + convertObjectToQueryParams(innFilter);
    } else {
      const fullNameFilter = { filters: [{ id: 'fullName', value }] };
      filters = '&' + convertObjectToQueryParams(fullNameFilter);
    }

    try {
      const result = await getEmployeesList(1, 'items=1000', '', filters);
      const items = result.rows?.map((employee: EmployeeRequestModel) => {
        let value = employee.fullName;

        if (employee.inn) {
          value += ` (ИНН: ${employee.inn})`;
        }

        return {
          id: employee.id.toString(),
          value,
        };
      });

      return items;
    } catch (error) {
      console.error(error);

      return [];
    }
  }

  @Action({ rawError: true })
  async saveRelation(): Promise<boolean> {
    let isSuccessResponse = false;

    try {
      this.context.commit('SET_IS_SAVING', true);

      const preparedModel: {
        client: string;
        employee: string;
        startDate: string | boolean;
        endDate?: string;
      } = {
        client: this.model.client.id,
        employee: this.model.employee.id,
        startDate: !!this.model.startDate && moment(this.model.startDate).format('YYYY-MM-DD'),
      };

      const isFilledModel = Object.values(preparedModel).every(Boolean);

      if (!isFilledModel) {
        throw new Error('Заполните обязательные поля');
      }

      if (this.model.endDate) {
        preparedModel.endDate = moment(this.model.endDate).format('YYYY-MM-DD');
      }

      await createLaborRelation(preparedModel as Record<string, string>);

      ResponseHandlerModule.showNotify({
        message: 'Трудовые отношения созданы',
        type: 'ok',
      });

      this.context.commit('CLEAR_MODEL');
      await this.getList();
      isSuccessResponse = true;
    } catch (error) {
      const errorFields = error.response?.data.errors.fields;
      const message = errorFields ? Object.values(errorFields).join('; ') : error.message || strings.UNKNOWN_ERROR;

      ResponseHandlerModule.showNotify({ message, type: 'fail' });
    } finally {
      this.context.commit('SET_IS_SAVING', false);
    }

    return isSuccessResponse;
  }

  @Action({ rawError: true })
  async removeRelation(id: string): Promise<void> {
    try {
      this.context.commit('UPDATE_IS_LOADING', true);
      await deleteLaborRelation(id);

      ResponseHandlerModule.showNotify({
        message: strings.SUCCESS_DELETE,
        type: 'ok',
      });

      await this.getList();
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: error.response?.data.errors.fields ?? strings.UNKNOWN_ERROR,
        type: 'fail',
      });
    } finally {
      this.context.commit('UPDATE_IS_LOADING', false);
    }
  }
}

export default getModule(LaborRelationsModule);
