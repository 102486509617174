
import { Ref, WritableComputedRef, computed, defineComponent, ref } from 'vue';
import moment from 'moment';
import { GuiDatePicker } from '@library/gigant_ui';
import { debounce } from '@/lib/util/helpers';
import { DatePickerEntityList } from '@/lib/layouts/page/filterDatePicker.interface';
import FormInput from '@/components/form/Input/index.vue';
import FormSelect from '@/components/form/Select/index.vue';
import LaborRelationsModule from '@/store/settings/laborRelations';
import useDateRange from './dateRange';

export default defineComponent({
  components: {
    FormInput,
    GuiDatePicker,
    FormSelect,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const isFocusFullName = ref(false);
    const filterSettings = computed(() => LaborRelationsModule.filter.filterSettings);
    const filterModel = computed(() => filterSettings.value.filterModel);

    const client = computed(() => filterModel.value.clientId.current);
    const dateFilters = computed(() => filterModel.value.date.list as DatePickerEntityList);
    const startDate = createDateRef('start');
    const endDate = createDateRef('end');

    const { minStartDate, maxStartDate, minEndDate, maxEndDate } = useDateRange(
      startDate as Ref<Date>,
      endDate as Ref<Date>,
      true
    );

    const updateFullNameFilter = debounce(async (name: string) => {
      await LaborRelationsModule.changeFilterName(name);
    }, 650);

    function createDateRef(type: 'start' | 'end'): WritableComputedRef<Date | number> {
      const filterKey = `${type}Date`;

      return computed<Date | number>({
        get() {
          if (!dateFilters.value[filterKey].value) {
            return 0;
          }

          return moment(dateFilters.value[filterKey].value, 'D/M/YYYY').toDate();
        },
        set(value: Date | number) {
          LaborRelationsModule.changeFilterDate({ type, value });
        },
      });
    }

    async function handleFilterSelect(params: Parameters<typeof LaborRelationsModule.changeFilterSelect>[0]) {
      await LaborRelationsModule.changeFilterSelect(params);
    }

    return {
      isFocusFullName,
      filterSettings,
      filterModel,
      client,
      startDate,
      endDate,
      minStartDate,
      maxStartDate,
      minEndDate,
      maxEndDate,
      handleFilterSelect,
      updateFullNameFilter,
    };
  },
});
