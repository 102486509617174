import { Ref, computed } from 'vue';
import moment from 'moment';

export default function useDateRange(
  startDateRef: Ref<Date | undefined>,
  endDateRef: Ref<Date | undefined>,
  isMaxToday: boolean
): {
  minStartDate: Date;
  maxStartDate: Ref<Date>;
  minEndDate: Ref<Date>;
  maxEndDate: Date;
} {
  const minStartDate = moment().add(-100, 'years').toDate();
  const maxEndDate = isMaxToday ? moment().toDate() : moment().add(100, 'years').toDate();

  const maxStartDate = computed(() => {
    const endDate = endDateRef.value || maxEndDate;

    return moment(endDate).add(-1, 'day').toDate();
  });

  const minEndDate = computed(() => {
    const startDate = startDateRef.value || minStartDate;

    return moment(startDate).add(1, 'day').toDate();
  });

  return { minStartDate, maxStartDate, minEndDate, maxEndDate };
}
