import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class LaborRelationsFilter {
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createSearchEntity('fio');
    filter.setTitle('fio', 'Исполнитель');
    filter.setName('fio', 'search_fio');
    filter.setAction('fio', 'laborRelations/updateSearch');

    filter.createSelectEntity('clientId');
    filter.setTitle('clientId', 'Клиент');
    filter.setName('clientId', 'select_client');
    filter.setInitAction('clientId', 'laborRelations/initClientsSelect');

    filter.createDateIntervalPickerEntity('date');
    filter.setName('date', 'picker_date');
    filter.setPreviewName('date', 'Дата');
    filter.setDateIntervalList('date', {
      startDate: {
        key: 'startDate',
        type: 'date-picker',
        title: '',
        placeholder: '',
        value: '',
      },
      endDate: {
        key: 'endDate',
        type: 'date-picker',
        title: '',
        placeholder: '',
        value: '',
      },
    });

    this.filterModel = filter.filterModel;
  }
}
